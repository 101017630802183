import { get, readable, writable } from "svelte/store";

// types
import type { NavItem } from "$types/types";

// data
import { default as navJSON } from "$data/nav.json";
const navData = navJSON as NavItem[];

// stores
export const navItems = readable(navData);
export const mobileNavIsOpen = writable(false);

export const branding = writable(null as HTMLAnchorElement | null);
export const hamburger = writable(null as HTMLButtonElement | null);
export const mobileLinkFirst = writable(null as HTMLAnchorElement | null);
export const mobileLinkLast = writable(null as HTMLAnchorElement | null);

// nav functions
export function setFocusOnBrandLink() {
  const brandLink = get(branding);
  if (brandLink) {
    brandLink.focus();
  }
}
export function setFocusOnHamburger() {
  const hamburgerButton = get(hamburger);
  if (hamburgerButton) {
    hamburgerButton.focus();
  }
}
export function setFocusOnFirstMobileNavLink() {
  const firstLink = get(mobileLinkFirst);
  if (firstLink) {
    firstLink.focus();
  }
}
export function setFocusOnLastMobileNavLink() {
  const lastLink = get(mobileLinkLast);
  if (lastLink) {
    lastLink.focus();
  }
}
export function toggleMobileNav() {
  mobileNavIsOpen.update((value) => !value);
}
export function closeMobileNav() {
  mobileNavIsOpen.set(false);
}
